// 专门用于获取下拉数据
// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson,
} from "./http/http";

const selectOpts = {
    // 设备下拉
    getDeviceList(parame) {
        return postJson(`/emp/type-model/findListNoAuthor`, parame);
    },
    /**
     * 查询用户列表
     * （引用用户信息）
     */
    getUserList(params) {
        return postJson(`/base/user/findListNoAuthor`, params);
    },
    /**
     * 关联项目下拉
     * （新建任务）
     */
    projectList(params) {
        return postJson("/project/project/findListNoAuthor", params);
    },
    // 项目机会下拉列表
    reserveProjectOpts(params) {
        return postJson("/reserveproject/reserve-project/findSelectListNoAuthor", params);
    },

    // 项目机会下拉列表
    getProjectListBystatus(params) {
        return postJson("/project/project/getSelectListWithAction", params);
    },
    // 公司下的联系人列表
    getPersonInDeptTree() {
        return get("/base/department/getDeptAndUserTree");
    },
    // 公司下的联系人列表（社保用  包含非当前公司人员）
    getPersonInWageDeptTree() {
        return get("/base/department/getDeptAndSalaryUserTree");
    },
};

export default selectOpts;
