// 资金管理
<template>

    <div class="moneyManage baseBg">
        <el-card shadow="never"
                 class="allCount"
                 :body-style="{ padding: '20px' }">
            <div slot="header">
                <span class="title">全部账户</span>
                <div class="hideBox flr">
                    <span class="label">隐藏金额</span>
                    <el-switch v-model="searchForm.encrypt"
                               @change="init"
                               active-color="#409EFF"
                               inactive-color="#909399"></el-switch>
                </div>
            </div>
            <!-- 银行卡 -->
            <el-row :gutter="40"
                    class="blankCardBox">
                <el-col :span="8"
                        v-for="(item,idx) in accountLis"
                        :key="idx">
                    <div class="bankCard"
                         :class="{defaultCard: !item.bgImg}"
                         :style="`backgroundImage: url(${item.bgImg})`">
                        <div class="baseInfo">
                            <div class="icon"
                                 :class="{defaultIco: !item.bankLogo}"
                                 :style="{backgroundImage: `url(${item.bankLogo})`}"></div>
                            <div class="right">
                                <div class="title ofh">
                                    <el-tooltip effect="dark"
                                                :content="item.name">
                                        <span class="name fll ellipsis"
                                              style="max-width: 290px">{{item.name}}</span>
                                    </el-tooltip>
                                    <span class="countNum flr"><span class="fack">****</span> {{item.account && item.account.slice(-4)}}</span>
                                </div>
                                <div class="typeTip">
                                    <span class="tip"
                                          v-if="item.type && item.type.text === '银行'">储蓄卡</span>
                                    <span class="tip"
                                          v-else>现金</span>
                                </div>
                                <div class="countBox">
                                    <div class="title">账户余额</div>
                                    <div class="count">{{item.balance}}</div>
                                </div>
                            </div>
                        </div>
                        <div class="control">
                            <el-popconfirm confirm-button-text="删除"
                                           cancel-button-text="取消"
                                           icon="el-icon-info"
                                           icon-color="red"
                                           @confirm="delAccount(item.id)"
                                           title="是否除当前账户，删除后无法恢复">
                                <span class="controlBtn"
                                      slot="reference">
                                    <svg-icon icon-class="icon_del" />删除
                                </span>
                            </el-popconfirm>
                            <span class="controlBtn"
                                  @click="openpopEditAccount(item.id)">
                                <svg-icon icon-class="icon_edit" />编辑
                            </span>
                            <span class="controlBtn"
                                  @click="toDetails(item.id, item.name)">
                                <svg-icon icon-class="icon_details" />明细
                            </span>
                            <span class="controlBtn"
                                  @click="openpopAccountDetails(item.id)">
                                <svg-icon icon-class="icon_view" />查看
                            </span>
                        </div>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="addAccountBox"
                         @click="openpopEditAccount()">
                        <div class="icon_addAccount"></div>
                        <div class="title">新增账户</div>
                    </div>
                </el-col>
            </el-row>

        </el-card>

        <el-row :gutter="20"
                class="accountView">
            <el-col :span="6">
                <el-card shadow="never"
                         class="accountViewItem">
                    <div class="itemBody">
                        <div class="icon">
                            <img src="../../assets/imgs/icon_moneyCount.png"
                                 alt="">
                        </div>
                        <div class="countBox">
                            <div class="title">余额</div>
                            <div class="num">{{searchForm.encrypt ? "**.**" : balance}}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="never"
                         class="accountViewItem">
                    <div class="itemBody">
                        <div class="icon">
                            <img src="../../assets/imgs/icon_baseMoney.png"
                                 alt="">
                        </div>
                        <div class="countBox">
                            <div class="title">初始余额</div>
                            <div class="num">{{searchForm.encrypt ? "**.**" : initialAmount}}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="never"
                         class="accountViewItem">
                    <div class="itemBody">
                        <div class="icon">
                            <img src="../../assets/imgs/icon_saveMoney.png"
                                 alt="">
                        </div>
                        <div class="countBox">
                            <div class="title">流入</div>
                            <div class="num">{{searchForm.encrypt ? "**.**" : inTotalAmount}}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card shadow="never"
                         class="accountViewItem">
                    <div class="itemBody">
                        <div class="icon">
                            <img src="../../assets/imgs/icon_payMoney.png"
                                 alt="">
                        </div>
                        <div class="countBox">
                            <div class="title">流出</div>
                            <div class="num">{{searchForm.encrypt ? "**.**" : outTotalAmount}}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <!-- 账户信息 -->
        <pop-account-details v-if="popAccountDetailsVisible"
                             :id="detailsId"
                             @close="closepopAccountDetails"></pop-account-details>
        <!-- 新增|编辑账户 -->
        <pop-edit-account v-if="popEditAccountVisible"
                          @updata="init()"
                          :id="detailsId"
                          @close="closepopEditAccount"></pop-edit-account>

    </div>

</template>

<script>
import PopEditAccount from "./components/popEditAccount";
import PopAccountDetails from "./components/popAccountDetails";
import { Loading } from "element-ui";
import API from "@/api/finance.js";
export default {
    name: "moneyManage",

    props: {},

    components: {
        PopEditAccount,
        PopAccountDetails,
    },

    data() {
        return {
            hideMoney: false, // 切换隐藏金额
            popAccountDetailsVisible: false, // 账户信息弹窗显示
            popEditAccountVisible: false, // 新增|编辑账户弹窗显示
            searchForm: {
                encrypt: true, // 金额是否加密，true=加密；false=不加密
            },
            balance: "", // 余额 number
            inTotalAmount: "", // 流入总额 number
            initialAmount: "", // 初始金额 number
            outTotalAmount: "", // 流出总额 number
            accountLis: [], // 全部账户
            detailsId: null, // 编辑所需id
        };
    },

    created() {},

    mounted() {
        this.init();
    },

    methods: {
        init() {
            // 获取账户列表
            this.getAccountLis();
            if (!this.searchForm.encrypt) {
                // 获取账户统计信息
                this.getAllAcountMoney();
            }
        },
        // 获取账户列表
        getAccountLis() {
            let loading = Loading.service({
                target: document.querySelector(".blankCardBox"),
            });
            API.getAccountLis(this.searchForm)
                .then((res) => {
                    loading.close();
                    this.accountLis = res.content;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 删除账户
        delAccount(id) {
            let loading = Loading.service({
                target: document.querySelector(".blankCardBox"),
            });
            API.delAccount(id)
                .then(() => {
                    loading.close();
                    this.$message({ message: "操作成功！", type: "success" });
                    this.init();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取账户统计信息
        getAllAcountMoney() {
            let loading = Loading.service({
                target: document.querySelector(".accountView"),
            });
            API.getAllAcountMoney()
                .then((res) => {
                    loading.close();
                    this.balance = res.content.balance || 0; // 余额 number
                    this.inTotalAmount = res.content.inTotalAmount || 0; // 流入总额 number
                    this.initialAmount = res.content.initialAmount || 0; // 初始金额 number
                    this.outTotalAmount = res.content.outTotalAmount || 0; // 流出总额 number
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 跳转到账户流水明细页面
        toDetails(id, name) {
            this.$router.push({
                path: "/moneyManage/accountInfoDetails",
                query: { id, name },
            });
        },
        // 账户信息 打开弹框
        openpopAccountDetails(id) {
            this.detailsId = id || null;
            this.popAccountDetailsVisible = true;
        },
        // 账户信息 关闭弹框
        closepopAccountDetails() {
            this.detailsId = null;
            this.popAccountDetailsVisible = false;
        },
        // 新增|编辑账户 打开弹框
        openpopEditAccount(id) {
            this.detailsId = id || null;
            this.popEditAccountVisible = true;
        },
        // 新增|编辑账户 关闭弹框
        closepopEditAccount() {
            this.detailsId = null;
            this.popEditAccountVisible = false;
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
// 银行卡
.allCount .el-card__body {
    padding-bottom: 0;
}
.blankCardBox {
    .el-col {
        min-width: 520px;
        .bankCard.defaultCard {
            background-image: url("../../assets/imgs/bankBg_cash.png") !important;
        }
        .bankCard {
            width: 520px;
            height: 212px;
            background: url("../../assets/imgs/bankBg_cash.png") no-repeat
                center center;
            background-size: 100% 100%;
            border-radius: 10px;
            padding: 22px 20px 8px;
            float: left;
            margin-bottom: 40px;
            box-sizing: border-box;
            .baseInfo {
                .icon.defaultIco {
                    background-image: url("../../assets/imgs/icon_cash.png") !important;
                }
                .icon {
                    width: 64px;
                    height: 64px;
                    background: #ffffff url("../../assets/imgs/icon_cash.png")
                        no-repeat center center;
                    background-size: 64px;
                    border-radius: 50%;
                    margin-right: 13px;
                    float: left;
                }
                .right {
                    float: left;
                    width: calc(100% - 77px);
                    .title {
                        padding-top: 8px;
                        line-height: 22px;
                        width: 100%;
                        .name {
                            font-size: 22px;
                            font-weight: 400;
                            color: #ffffff;
                        }
                        .countNum {
                            float: right;
                            font-size: 20px;
                            font-family: DIN;
                            font-weight: bold;
                            color: #ffffff;
                            .fack {
                                font-size: 20px;
                                font-weight: 400;
                                color: #ffffff;
                                opacity: 0.5;
                                margin-right: 14px;
                                position: relative;
                                top: 5px;
                            }
                        }
                    }
                    .typeTip {
                        clear: both;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        .tip {
                            display: inline-block;
                            text-align: center;
                            width: 50px;
                            height: 16px;
                            line-height: 16px;
                            background: #ffffff;
                            border-radius: 2px;
                            font-size: 12px;
                            color: #fe6f64;
                            padding: 0 4px;
                        }
                    }
                    .countBox {
                        width: 100%;
                        margin-bottom: 15px;
                        .title {
                            width: 65px;
                            font-size: 15px;
                            line-height: 15px;
                            color: #ffffff;
                            opacity: 0.5;
                            margin-bottom: 10px;
                        }
                        .count {
                            font-size: 32px;
                            line-height: 32px;
                            font-family: DIN;
                            font-weight: bold;
                            color: #ffffff;
                        }
                    }
                }
            }
            .control {
                padding-top: 0px;
                clear: both;
                .controlBtn {
                    display: inline-block;
                    cursor: pointer;
                    width: 70px;
                    height: 32px;
                    line-height: 32px;
                    background: rgba(0, 0, 0, 0.2);
                    border-radius: 4px;
                    font-size: 14px;
                    color: #ffffff;
                    text-align: center;
                    margin-right: 20px;
                    svg {
                        font-size: 14px;
                        margin-right: 8px;
                    }
                }
            }
        }
        .bankCard.jianhang {
            background-image: url("../../assets/imgs/bankBg_jianhang.png");
            .baseInfo .icon {
                background-image: url("../../assets/imgs/icon_jianhang.png");
            }
        }
        .bankCard.gonghang {
            background-image: url("../../assets/imgs/bankBg_gonghang.png");
            .baseInfo .icon {
                background-image: url("../../assets/imgs/icon_gonghang.png");
            }
        }
        .addAccountBox {
            width: 520px;
            height: 212px;
            background: #ffffff;
            border: 2px dashed #dcdfe6;
            border-radius: 10px;
            text-align: center;
            cursor: pointer;
            font-size: 20px;
            color: #010101;
            transition: all 0.5s;
            .icon_addAccount {
                width: 60px;
                height: 60px;
                background: url("../../assets/imgs/icon_addAccount.png");
                margin: 65px auto 24px;
            }
        }
        .addAccountBox:hover {
            opacity: 0.7;
            transition: all 0.5s;
        }
    }
}
// 全部账户
.allCount {
    margin-bottom: 20px;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }
    .hideBox {
        .label {
            font-size: 14px;
            color: #333333;
            margin-right: 13px;
        }
    }
}
// 金额
.accountViewItem {
    background: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(108, 116, 125, 0.1);
    border-radius: 6px;
    .itemBody {
        height: 160px;
        padding: 40px 0;
        display: flex;
        .icon {
            margin-right: 20px;
            img {
                width: 80px;
                height: 80px;
            }
        }
        .countBox {
            height: 72px;
            .title {
                font-size: 20px;
                color: #999999;
                margin-bottom: 15px;
            }
            .num {
                font-size: 32px;
                font-family: DIN;
                font-weight: bold;
                color: #333333;
            }
        }
    }
}
</style>
