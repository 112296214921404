// 新增|编辑账户
// popEditAccount
<template>

    <div class="popEditAccount">
        <el-dialog :title="id?'编辑账户':'新增账户'"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   width="850"
                   :before-close="close">
            <div class="loadingBox_1627955701">
                <el-form :model="form"
                         ref="form"
                         :rules="formRules"
                         label-position="top"
                         :inline="false">
                    <el-row :gutter="40">
                        <el-col :span="8">
                            <el-form-item label="账户类型"
                                          prop="type">
                                <el-select v-model="form.type"
                                           placeholder="请选择账户类型"
                                           clearable>
                                    <el-option v-for="item in accountType"
                                               :key="item.code"
                                               :label="item.label"
                                               :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="账户名"
                                          prop="name">
                                <el-input v-model="form.name"
                                          placeholder="请输入账户名"
                                          clearable></el-input>
                            </el-form-item>
                        </el-col>
                        <template v-if="form.type === 'BANK'">
                            <el-col :span="8">
                                <el-form-item label="开户行"
                                              prop="bankId">
                                    <el-select v-model="form.bankId"
                                               placeholder="请选择开户行"
                                               clearable>
                                        <el-option v-for="item in bankOpts"
                                                   :key="item.value"
                                                   :label="item.label"
                                                   :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="开户人"
                                              prop="accountHolder">
                                    <el-input v-model="form.accountHolder"
                                              placeholder="请输入开户人"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="账号"
                                              prop="account">
                                    <el-input v-model="form.account"
                                              placeholder="请输入账号"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>
                        </template>
                        <el-col :span="8">
                            <el-form-item label="对应会计科目"
                                          prop="accountingSubject">
                                <el-select v-model="form.accountingSubject"
                                           placeholder="请选择对应会计科目"
                                           clearable>
                                    <el-option v-for="item in accountSubjectTypeOpts"
                                               :key="item.code"
                                               :label="item.label"
                                               :value="item.code">
                                    </el-option>
                                </el-select>

                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="初始金额（元）"
                                          prop="initialAmount">
                                <el-input-money v-model="form.initialAmount"
                                                placeholder="请输入初始金额"
                                                clearable></el-input-money>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="初始日期"
                                          prop="initialDate">
                                <el-date-picker v-model="form.initialDate"
                                                type="date"
                                                value-format="yyyy-MM-dd"
                                                placeholder="请选择入初始日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="可用员工（不选则为全部员工）"
                                          prop="userIds">
                                <!-- 选择可用员工 -->
                                <pop-select-members v-if="selectMembersPersonVisible"
                                                    title="选择可用员工"
                                                    :showCheckbox="true"
                                                    :personIdArr="form.userIds"
                                                    @close="closeselectMembersPerson"></pop-select-members>
                                <span v-for="(item,idx) in form.users"
                                      :key="idx">{{item.name}}、</span>
                                <el-link type="primary"
                                         @click="openselectMembersPerson">选择可用员工</el-link>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="备注"
                                          prop="remark">
                                <el-input v-model="form.remark"
                                          type="textarea"
                                          rows="5"
                                          placeholder="请输入备注"
                                          clearable></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div class="footer">
                        <el-button type="danger"
                                   @click="close">取消</el-button>
                        <el-button type="primary"
                                   @click="save">保存</el-button>
                    </div>
                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import PopSelectMembers from "../../../components/selectTree/popSelectMembers";
import ElInputMoney from "../../../components/form/elInputMoney";
import API from "@/api/finance.js";
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
export default {
    name: "popEditAccount",

    props: {
        // 查询详情用id
        id: {
            type: String,
            default: null,
        },
    },

    components: {
        PopSelectMembers,
        ElInputMoney,
    },

    data() {
        return {
            dialogVisible: true,
            form: {
                type: "", // 账户类型
                name: "", // 账户名
                accountHolder: "", // 开户人
                account: "", // 账号
                category: "", // 对应会计科目
                initialAmount: "", // 初始金额
                initialDate: "", // 初始日期
                userIds: [], // 可用员工
                remark: "", // 备注
            },
            formRules: {
                type: {
                    required: true,
                    message: "请验证账户类型",
                    trigger: "blur",
                }, // 账户类型
                name: {
                    required: true,
                    message: "请验证账户名",
                    trigger: "blur",
                }, // 账户名
                account: {
                    required: true,
                    message: "请验证账号",
                    trigger: "blur",
                }, // 账号
                initialAmount: {
                    required: true,
                    message: "请验证初始金额",
                    trigger: "blur",
                }, // 初始金额
            },
            options: [], // 对应会计科目下拉
            bankOpts: [], // 开户行下拉
            selectMembersPersonVisible: false, // 选择客户弹窗显示
        };
    },

    created() {},

    mounted() {
        if (this.id) {
            // 查询账户详情
            this.getAccountDetails();
        } else {
            this.form.initialDate = this.$moment().format("YYYY-MM-DD");
        }
        // 获取开户行下拉
        this.getBankOpts();
    },

    methods: {
        // 查询账户详情
        getAccountDetails() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            API.getAccountDetails(this.id)
                .then((res) => {
                    loading.close();
                    this.form = res.content;
                    this.form.type = this.form.type.name || null;
                    this.form.accountingSubject =
                        this.form.accountingSubject.name || null;
                    let userIds = [];
                    this.form.users.forEach((item) => {
                        userIds.push(item.id);
                    });
                    this.form.userIds = userIds;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取开户行下拉
        getBankOpts() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            API.getBankOpts()
                .then((res) => {
                    loading.close();
                    let data = res.content;
                    let opt = data.map((val) => {
                        return {
                            label: val["name"],
                            value: val["id"],
                        };
                    });
                    this.bankOpts = opt;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 保存账户信息
        save() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    let loading = Loading.service({
                        target: document.querySelector(".el-dialog"),
                    });
                    let data = Object.assign({}, this.form, {
                        users: null,
                    });
                    API.saveAccount(data)
                        .then(() => {
                            loading.close();
                            this.$message({
                                message: "操作成功！",
                                type: "success",
                            });
                            this.$emit("updata");
                            this.close();
                        })
                        .catch(() => {
                            loading.close();
                        });
                }
            });
        },
        // 选择添加可用人员
        openselectMembersPerson() {
            this.selectMembersPersonVisible = true;
        },
        // 关闭选择添加可用人员弹框
        closeselectMembersPerson(data) {
            if (data) {
                this.form.userIds = data.idArr;
                let userList = data.choosePersonInfo.map((item) => {
                    return {
                        id: item.id,
                        name: item.userName,
                    };
                });
                this.form.users = userList;
            }
            this.selectMembersPersonVisible = false;
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {
        ...mapGetters(["accountType", "accountSubjectTypeOpts"]),
    },

    watch: {},
};
</script>

<style lang="scss" scoped>
</style>