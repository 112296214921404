// 财务模块
// import request from '@/utils/request'
import {
    get,
    // post,
    // postForm,
    postJson
} from './http/http';

const httpServer = {
    // ----------------------- 资金账户 -----------------------
    ...{
        // 资金账户列表
        getAccountLis(params) {
            return get(`/capital/account/list`, params);
        },
        // 资金账户详情
        getAccountDetails(id) {
            return get(`/capital/account/findById/${id}`);
        },
        // 资金账户下拉列表
        getAccountOpts(params) {
            return get(`/capital/account/selectList`, params);
        },
        // 供应商账户下拉列表
        getCusAccountOpts(id) {
            return get(`/baseinfo/company/bank/${id}`);
        },
        // 开户行下拉
        getBankOpts() {
            return get(`/bank/list`);
        },
        // 员工账户下拉列表
        getMemberAccountOpts(params) {
            return get(`/user/account/selectList`, params);
        },
        // 统计所有账户金额及流水
        getAllAcountMoney(params) {
            return get(`/capital/account/statistics`, params);
        },
        // 保存账户信息
        saveAccount(params) {
            return postJson('/capital/account/save', params);
        },
        // 删除账户
        delAccount(id) {
            return get(`/capital/account/delete/${id}`);
        },
        // 查询资金账户流水
        getAccountInfoDetails(params) {
            return postJson('/capital/account/detail/table', params);
        },
        // 查询资金流水列表
        getAccountFlowTable(params) {
            return postJson('/capital/account/detail/flow/table', params);
        },
        // 查询资金流水-收付款记录详情
        getPayHisDetails(id) {
            return get(`/cashier/payment/record/find/${id}`);
        },
        // 查询资金调拨-tabs数量统计
        moneyChangeCount() {
            return get(`/ca/fund-transfer/count`);
        },
        // 查询资金调拨列表
        getMoneyChangeTable(params) {
            return postJson('/ca/fund-transfer/table', params);
        },
        // 保存资金调拨
        saveMoneyChange(params) {
            return postJson('/ca/fund-transfer/save', params);
        },
        // 确认资金调拨
        saveMoneyChangeApply(params) {
            return postJson('/ca/fund-transfer/take/effect', params);
        },
        // 查询资金调拨-详情
        getMoneyChangeDetails(id) {
            return get(`/ca/fund-transfer/findById/${id}`);
        },
        // 撤回资金调拨
        rebackMoneyChangeApply(id) {
            return get(`/ca/fund-transfer/withdraw/${id}`);
        },
        // 删除资金调拨
        delMoneyChange(id) {
            return get(`/ca/fund-transfer/delete/${id}`);
        },
        // 出纳收款|付款-列表
        getCashierCollectionTable(params) {
            return postJson('/cashier/payment/table', params);
        },
        // 出纳收款-列表-历史
        getCashierCollectionRecordTable(params) {
            return postJson('/cashier/payment/record/table', params);
        },
        // 出纳收款-列表-历史删除
        delCashierCollectionRecord(id) {
            return get(`/cashier/payment/record/delete/${id}`);
        },
        // 出纳收款-列表统计数量
        getCashierCollectionCount() {
            return postJson('/cashier/payment/count/income');
        },
        // 出纳付款-发工资
        getCashierSalaryTable(params) {
            return postJson('/cashier/payment/salary/table', params);
        },
        // 出纳付款-列表统计数量
        getPayCollectionCount() {
            return postJson('/cashier/payment/count/expenditure');
        },
        // 出纳收款
        saveCashierCollection(params) {
            return postJson('/cashier/payment/record/gathering', params);
        },
        // 出纳付款
        saveCashierCollectionPay(params) {
            return postJson('/cashier/payment/record/pay', params);
        },
        // 出纳付款-发工资-获取工资条
        getCashierSalaryDetails(id) {
            return get(`/cashier/payment/salary/detail/${id}`);
        },
        // 出纳付款-发工资
        saveCashierSalaryPay(params) {
            return postJson('/cashier/payment/record/pay/salary', params);
        },
        // 出纳付款-税费付款
        saveCashierTaxPay(params) {
            return postJson('/cashier/payment/record/pay/tax', params);
        },
        // 出纳付款-税费付款
        getCashierTaxationPay(params) {
            return postJson('/cashier/payment/tax/table', params);
        },
        // 移除付款记录
        removePayHis(id) {
            return get(`/ca/imprest/delete/${id}`);
        },
        // 出纳付款-冲销预付款
        saveCashierWriteoffAccounts(params) {
            return postJson(`/ca/imprest/cashier-save`, params);
        }
    },

    // ----------------------- 付款管理 -----------------------
    ...{
        // 付款申请列表
        getPayApplyList(params) {
            return postJson('/payment/management/table', params);
        },
        // 付款申请列表 统计
        getPayApplyCount(params) {
            return get('/payment/management/count', params);
        },
        // 删除付款管理
        delPayApply(id) {
            return get(`/payment/management/delete/${id}`);
        },
        // 获取付款申请详情
        getPayDetails(id) {
            return get(`/payment/management/findById/${id}`);
        },
        // 新增付款申请
        addPayApply(params) {
            return postJson('/payment/management/save', params);
        },
        // 保存付款申请 带审核信息
        savePayApply(params) {
            return postJson('/payment/management/take/effect', params);
        },
        // 撤回付款申请
        rebackPayApply(id) {
            return get(`/payment/management/withdraw/${id}`);
        },
        // 撤回其他收入
        rebackOtherPay(id) {
            return get(`/income/expenses-other/withdraw/${id}`);
        },
        // 获取供应商是否需要冲销
        offsetCompanyMoney(params) {
            return get(`/ca/imprest/find-usable`, params);
        }
    },

    // ------------------- 收入管理 ------------------
    ...{
        // 收入管理列表
        getIncomeApplyList(params) {
            return postJson('/finance/gathering-management/table', params);
        },
        // 收入管理列表计数
        getIncomeApplyCount() {
            return get(`/finance/gathering-management/count`);
        },
        // 获取收款申请详情
        getIncomeDetails(id) {
            return get(`/finance/gathering-management/findById/${id}`);
        },
        // 新增 | 保存 付款申请
        addIncomeApply(params) {
            return postJson('/finance/gathering-management/save', params);
        },
        // 保存收款申请 带审核信息
        saveIncomeApply(params) {
            return postJson('/finance/gathering-management/take/effect', params);
        },
        // 撤回收款申请
        rebackIncomeApply(id) {
            return get(`/finance/gathering-management/withdraw/${id}`);
        },
        // 删除付款管理
        delIncomeApply(id) {
            return get(`/finance/gathering-management/delete/${id}`);
        }
    },

    // ----------------------- 操作日志 -----------------------
    ...{
        // 获取操作日志
        getControlLog(params) {
            return get(`/operation/log/list`, params);
        }
    },

    // ----------------------- 税费 -----------------------
    ...{
        // 获取缴费汇总列表
        getAllTaxationList(params) {
            return get(`/tax/bill/summary`, params);
        },
        // 获取缴税费记录列表
        getTaxationRecordList(params) {
            return postJson(`/tax/bill/table`, params);
        },
        // 保存缴税费
        saveTaxa(params) {
            return postJson(`/tax/bill/save`, params);
        },
        // 保存退税费
        saveRefundTaxa(params) {
            return postJson(`/tax/refund/bill/save`, params);
        },
        // 根据id查询缴税费详情
        getTaxaDetails(id) {
            return get(`/tax/bill/detail/${id}`);
        },
        // 删除缴税费记录
        delTaxa(id) {
            return get(`/tax/bill/delete/${id}`);
        },
        // 撤回缴税费
        rebackTaxa(id) {
            return get(`/tax/bill/withdraw/${id}`);
        },
        // 税费付款明细
        taxPayDetails(id) {
            return postJson(`/tax/bill-detail/payment-record-detail/list/${id}`);
        }
    },
    // ----------------------- 股东管理 -----------------------
    // 获取股东列表
    getShareholdersLis(params) {
        return postJson(`/holder/stock-holder/table`, params);
    },
    // 保存股东
    saveShareholders(params) {
        return postJson(`/holder/stock-holder/save`, params);
    },
    // 删除股东
    delShareholders(id) {
        return get(`/holder/stock-holder/delete/${id}`);
    },
    // 获取股东下拉
    getShareholderOpts(params) {
        return get(`/holder/stock-holder/getSelectList`, params);
    },
    // ----------------------- 股本管理 -----------------------
    // 获取股本列表
    getEquityLis(params) {
        return postJson(`/holder/stock-flow/table`, params);
    },
    // 保存股本
    saveHandleEquity(params) {
        return postJson(`/holder/stock-flow/save`, params);
    },
    // 确认股本
    effectHandleEquity(params) {
        return postJson(`/holder/stock-flow/take/effect`, params);
    },
    // 获取股本详情
    getEquityDetails(id) {
        return get(`/holder/stock-flow/detail/${id}`);
    },
    // 删除股本
    delEquity(id) {
        return get(`/holder/stock-flow/delete/${id}`);
    },
    // 撤回股本
    rebackEquity(id) {
        return get(`/holder/stock-flow/withdraw/${id}`);
    },
    // ----------------------- 期初 -----------------------
    ...{
        // 获取合同期初列表
        getContractQichuList(params, menuId) {
            return postJson(`/early-contract/table/${menuId}`, params);
        },
        // 获取期初详情
        getQichuDetails(id) {
            return get(`/early-contract/find/${id}`);
        },
        // 编辑期初
        saveQichu(params) {
            return postJson(`/early-contract/edit`, params);
        },
        // 删除期初
        delQichu(id) {
            return postJson(`/early-contract/delete/${id}`);
        },
        // 获取其他期初列表
        getOtherQichuList(params, menuId) {
            return postJson(`/early-income-expenses-other/table/${menuId}`, params);
        },
        // 获取期初详情
        getOtherQichuDetails(id) {
            return get(`/early-income-expenses-other/find/${id}`);
        },
        // 编辑期初
        saveOtherQichu(params) {
            return postJson(`/early-income-expenses-other/edit`, params);
        },
        // 删除期初
        delOtherQichu(id) {
            return postJson(`/early-income-expenses-other/delete/${id}`);
        },
        // 获取期初合同金额统计
        getQichuContractAmount(type) {
            return get(`/early-contract/statistics/${type}`);
        },
        // 获取期初其他金额统计
        getQichuOtherAmount(type) {
            return get(`/early-income-expenses-other/statistics/${type}`);
        }
    },
    // ----------------------- 报销 -----------------------
    ...{
        // 费用-列表数量
        getCostListCount() {
            return get(`/expenses/expenses/count`);
        },
        // 费用-列表
        getCostList(params) {
            return postJson(`/expenses/expenses/table`, params);
        },
        // 费用-列表-无权限版
        getCostListNoAuth(params) {
            return postJson(`/expenses/expenses/expenses-list`, params);
        },
        // 费用-保存
        saveCost(params) {
            return postJson(`/expenses/expenses/save`, params);
        },
        // 费用-删除
        delCostList(id) {
            return get(`/expenses/expenses/delete/${id}`);
        },
        // 费用单据列表
        getBillTicketLis(params) {
            return get(`/invoice/application/expense-account`, params);
        },
        // 员工-列表数量
        getEmployeeBorrowMoneyListCount() {
            return get(`/advances/employee/count`);
        },
        // 员工-列表
        getEmployeeBorrowMoneyList(params) {
            return postJson(`/advances/employee/table`, params);
        }
    },
    // ----------------------- 发票 -----------------------
    ...{
        // 开票申请-列表数量
        getBillApplyCount() {
            return get(`/invoice/application/count`);
        },
        // 开票申请-列表
        getBillApplyList(params) {
            return postJson(`/invoice/application/table`, params);
        },
        // 开票申请-详情-编辑用
        getBillApplyDetails(id) {
            return get(`/invoice/application/edit/${id}`);
        },
        // 开票申请-详情-详情用
        getBillApplyDetailsInfo(id) {
            return get(`/invoice/application/detail/${id}`);
        },
        // 开票申请-保存
        saveBillApply(params) {
            return postJson(`/invoice/application/save`, params);
        },
        // 开票申请-确认
        confirmBillApply(params) {
            return postJson(`/invoice/application/confirm`, params);
        },
        // 开票申请-删除
        delBillApply(id) {
            return get(`/invoice/application/delete/${id}`);
        },
        // 开票申请-撤回
        rebackBillApply(id) {
            return get(`/invoice/application/withdraw/${id}`);
        },
        // 收入单据列表-合同收支
        getContractBillList(params) {
            return get(`/invoice/application/contract`, params);
        },
        // 收入单据列表-其他收支
        getOtherBillList(params) {
            return get(`/invoice/application/expenses-other`, params);
        },
        // 开票-详情
        getBillDetailsInfo(id) {
            return get(`/invoice/output-invoice/invoice-page/${id}`);
        },
        // 开票-保存
        saveBillDetailsInfo(params) {
            return postJson(`/invoice/output-invoice/save`, params);
        },
        // 销项发票|进项发票-列表
        getInputBillList(params) {
            return postJson(`/invoice/output-invoice/table`, params);
        },
        // 销项发票-新增
        saveOutputBill(params) {
            return postJson(`/invoice/output-invoice/save`, params);
        },
        // 进项发票-新增
        saveInputBill(params) {
            return postJson(`/invoice/input-invoice/save`, params);
        },
        // 进项发票-详情
        getInputBill(id) {
            return get(`/invoice/input-invoice/detail/${id}`);
        },
        // 销项发票-详情
        getOutBill(id) {
            return get(`/invoice/output-invoice/detail/${id}`);
        },
        // 进项发票-删除
        delInputBill(id) {
            return get(`/invoice/input-invoice/delete/${id}`);
        },
        // 销项发票-删除
        delOutputBill(id) {
            return get(`/invoice/output-invoice/delete/${id}`);
        },
        // 销项发票-作废
        invalidOutputBill(id) {
            return get(`/invoice/output-invoice/invalid/${id}`);
        }
    },
    // ----------------------- 报销 -----------------------
    ...{
        // 报销单-列表数量
        getReimbursementCount() {
            return get(`/expenses/expense-account/count`);
        },
        // 报销单-列表
        getReimbursementList(params) {
            return postJson(`/expenses/expense-account/table`, params);
        },
        // 报销-费用列表-查看详情
        getCostDetails(id) {
            return get(`/expenses/expenses/findById/${id}`);
        },
        // 报销-报销单-查看详情
        getReimbursementDetails(id) {
            return get(`/expenses/expense-account/detail/${id}`);
        },
        // 报销-报销单-撤回
        rebackReimbursement(id) {
            return get(`/expenses/expense-account/withdraw/${id}`);
        },
        // 报销-报销单-删除
        delReimbursement(id) {
            return get(`/expenses/expense-account/delete/${id}`);
        },
        // 报销-报销单-保存
        saveReimbursement(params) {
            return postJson(`/expenses/expense-account/save`, params);
        },
        // 报销-报销单-确认
        submitReimbursement(params) {
            return postJson(`/expenses/expense-account/take/effect`, params);
        }
    },

    // ------------------- 工资 ------------------
    ...{
        // 查询工资表
        getAllsalaryList(params) {
            return postJson('/salary/wage-schedule/table', params);
        },
        // 删除工资表
        deleteSalaryList(id) {
            return get(`/salary/wage-schedule/delete/${id}`);
        },
        // 保存取个人工资条信息(返回id)-v3.8.0
        savePersonWage(params) {
            return postJson('/salary/wage-schedule-staff/save', params);
        },
        //保存工资信息表
        saveSalaryWage(params) {
            return postJson('/salary/wage-schedule/save', params);
        },
        //删除缓存工资表数据
        deleteunuseWage(wageScheduleId) {
            return get(`/salary/wage-schedule-staff/delete/unuse/${wageScheduleId}`);
        },

        //获取工资表详情
        getSalaryWageDetail(params) {
            return postJson('/salary/wage-schedule/detail', params);
        },
        //获取个人工资条详情
        getPersonWageDetail(params) {
            return postJson('/salary/wage-schedule-staff/detail', params);
        },

        // 修改查看薪资密码
        updateSalaryPwd(params) {
            return postJson('/salary/wage-schedule/updateSalaryPwd', params);
        },

        //验证查看工资密码是否正确
        checkSalaryPwd(params) {
            return postJson('/salary/wage-schedule/checkSalaryPwd', params);
        },

        // 确认工资表
        effectSalary(params) {
            return postJson('/salary/wage-schedule/take/effect', params);
        },

        // 获取导入后的列表
        getstaffList(params) {
            return postJson('/salary/wage-schedule-staff/list', params);
        },

        // 获取工资表详情
        getwageDetail(params) {
            return postJson('/salary/wage-schedule/detail', params);
        },

        // 工资表撤回操作
        withdrawWage(id) {
            return get(`/salary/wage-schedule/withdraw/${id}`);
        },

        // 获取社保信息配置
        getSocialSecurity() {
            return get(`/social/security/config/info`);
        },

        // 保存社保信息配置
        saveSocialSecurity(params) {
            return postJson(`/social/security/config/save`, params);
        },

        // 保存其他人员薪资档案
        saveOtherSalary(params) {
            return postJson(`/salary/add/other`, params);
        },

        // 保存调整薪资档案
        saveAdjustSalary(params) {
            return postJson(`/salary/adjustment`, params);
        },

        // 获取薪酬档案列表数量统计
        getSalaryCount() {
            return get(`/salary/count`);
        },
        // 查询薪资详情
        getSalaryDetails(params) {
            return postJson(`/salary/detail`, params);
        },
        // 保存个人社保信息
        saveSecurity(params) {
            return postJson(`/salary/social-security-staff/save`, params);
        },
        //获取社保列表
        getPersonSecurityList(params) {
            return postJson(`/salary/social-security-staff/list`, params);
        },
        //删除未使用的个人社保信息（取消保存社保表时）-v3.13.0
        deleteunuseSecurity(socialSecurityId) {
            return get(`/salary/social-security-staff/delete/unuse/${socialSecurityId}`);
        },
        //保存工资信息表
        saveSalarySecurity(params) {
            return postJson('/salary/social-security/save', params);
        },
        // 确认社保
        effectSecurity(params) {
            return postJson('/salary/social-security/take/effect', params);
        },
        // 删除社保表
        deleteSecurity(id) {
            return get(`/salary/social-security/delete/${id}`);
        },
        // 获取社保详情
        getSalarySecurityDetail(params) {
            return postJson('/salary/social-security/detail', params);
        },
        //根据员工id和月份数获取人员社保信息-v3.13.0
        getDetailByUserIdAndMonth(params) {
            return postJson('/salary/social-security-staff/detailByUserIdAndMonth', params);
        },
        // 查询调薪记录
        getAdjustSalaryHistory(params) {
            return postJson(`/salary/salary-adjustment-record/list`, params);
        },
        // 查询调薪记录详情
        getAdjustSalaryHistoryDetails(params) {
            return postJson(`/salary/salary-adjustment-record/detail`, params);
        },
        // 工资付款明细
        salaryPayDetails(id) {
            return get(`/cashier/payment/record/salary/details/${id}`);
        },
        //自动获取工资或社保
        autoAccessSalary(params) {
            return get(`/salary/obtain`, params);
        },

        // 社保表撤回操作
        withdrawSecurity(id) {
            return get(`/salary/social-security/withdraw/${id}`);
        }
    },

    // ------------------- 个人账户 ------------------
    ...{
        // 保存用户银行账户
        saveUserAccount(params) {
            return postJson('/user/account/save', params);
        },
        //删除用户账户信息
        deleteAccount(id) {
            return get(`/user/account/delete/${id}`);
        }
    }
};
export default httpServer;
