// 添加成员
<template>

    <div class='selectPerson'>
        <el-dialog :close-on-click-modal="false"
                   :title="title"
                   :visible.sync="dialogVisible"
                   width="30%"
                   :before-close="close"
                   :modal-append-to-body='false'>
            <el-row :gutter="15">
                <el-col :span="12">
                    <div class="treeBox">
                        <el-tree class="filter-tree"
                                 :data="customTreeData"
                                 :props="defaultProps"
                                 node-key="id"
                                 @node-click="handleNodeClick"
                                 @check="handleNodeCheck"
                                 :default-expand-all="false"
                                 :filter-node-method="filterNode"
                                 :expand-on-click-node="expandOnClickNode"
                                 ref="tree">
                        </el-tree>
                    </div>
                </el-col>
                <el-col :span="12">
                    <div class="personList">
                        <template v-if="personList.length > 0">
                            <template v-if="showCheckbox">
                                <el-checkbox-group v-model="operatorIds">
                                    <p v-for="(item,idx) in personList"
                                       :key="idx">
                                        <el-checkbox :label="item.id">{{item.userName}}<span v-if="isNeedPositionName"> — {{item.roleName}}</span></el-checkbox>
                                    </p>
                                </el-checkbox-group>
                            </template>
                            <template v-else>
                                <el-radio-group v-model="operatorId">
                                    <p v-for="(item,idx) in personList"
                                       :key="idx">
                                        <el-radio :label="item.id">{{item.userName}}<span v-if="isNeedPositionName"> — {{item.roleName}}</span></el-radio>
                                    </p>
                                </el-radio-group>
                            </template>
                        </template>
                        <div class="emptyBg"
                             v-else></div>
                    </div>
                </el-col>
            </el-row>
            <!-- <p>已选：万物理想/技术中台/黄杉</p> -->
            <template v-if="showCheckbox">
                <p v-if="personNames">已选：{{personNames}}</p>
            </template>
            <template v-else>
                <p v-if="personName">已选：{{personName}}</p>
            </template>
            <div class="footer">
                <el-button type="primary"
                           @click="close">取消</el-button>
                <el-button type="success"
                           @click="onSubmit">确认</el-button>
            </div>

        </el-dialog>
    </div>

</template>

<script>
import { Loading } from "element-ui";
import commonAPI from "@/api/commonAPI.js";
import selectOptsAPI from "@/api/selectOpts.js";

export default {
    name: "selectPerson",

    props: {
        title: {
            type: String,
            default: "添加成员",
        },
        defaultProps: {
            type: Object,
            default() {
                return {
                    children: "children",
                    label: "label",
                };
            },
        },
        //是否显示多选
        showCheckbox: {
            type: Boolean,
            default: false,
        },
        //组织冒泡关闭
        expandOnClickNode: {
            type: Boolean,
            default: false,
        },
        //当前筛选类型 PROVIDER：供应商信息  CUSTOMER：客户信息
        type: {
            type: String,
            default: "CUSTOMER",
        },
        // 是否需要名称后跟随岗位名称
        isNeedPositionName: {
            type: Boolean,
            default: false,
        },
        // 是否需要排除指定用户
        filterPersonIdArr: {
            type: Array,
            default() {
                return [];
            },
        },
        // 回显已勾选用户的id
        personIdArr: {
            type: Array,
        },
        // 根据项目查询
        projectId: {
            type: String,
            default: "",
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            treeData: [
                {
                    label: "全部人员",
                },
            ],
            customTreeData: [], // 客户树
            personList: [], // 人员列表
            personName: "", // 已选择人员-名字
            operatorId: "", // 已选人员id
            chooseTree: {
                label: "",
                value: "",
            }, // 已选树完整信息
            choosePerson: {}, // 已选人员完整信息
            operatorIds: [], // 已选人员id-多选
            choosePersonList: [], // 已选人员完整信息-多选
            personNames: "", // 选中的人的名字-多选
        };
    },

    created() {},

    mounted() {
        //获取客户/供应商下拉树
        this.getOrgList();
        if (this.personIdArr) {
            this.operatorIds = this.personIdArr;
        }
    },

    methods: {
        //获取客户/供应商下拉树
        getOrgList() {
            let loading = Loading.service({
                target: document.querySelector(".treeBox"),
            });
            return commonAPI
                .getOrgList({
                    type: this.type,
                })
                .then((res) => {
                    this.customTreeData = res.content;
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 公司下的联系人列表
        getPersonList(companyId) {
            let loading = Loading.service({
                target: document.querySelector(".personList"),
            });
            return selectOptsAPI
                .getUserList({
                    deptId: companyId,
                    projectId: this.projectId || "",
                    status: "NORMAL",
                    size: 1000,
                })
                .then((res) => {
                    if (this.filterPersonIdArr.length) {
                        this.personList = res.content.records.filter((item) => {
                            return (
                                this.filterPersonIdArr.indexOf(item.id) === -1
                            );
                        });
                    } else {
                        this.personList = res.content.records;
                    }
                    // 根据id数组查询name
                    this.findPersonName();
                    loading.close();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 筛选 树
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        //下拉树的选择
        handleNodeClick(node) {
            this.chooseTree.label = node.label;
            this.chooseTree.value = node.id;
        },
        //多选时的选中事件
        handleNodeCheck() {
            let checkedNodes = this.$refs.tree.getCheckedNodes();
            let checkNode = checkedNodes
                // .filter((v) => !v.hasOwnProperty("children"))
                .map((v) => v.name);
            this.chooseTree.label = checkNode.join(",");
            this.$emit("treeSelectNodeClick", checkedNodes);
        },
        // 清空选择
        cleanSelect() {
            this.$refs.tree.setCheckedKeys([]); // 清除选中数组
            this.$refs.tree.setCurrentKey(null); // 清除高亮
            this.chooseTree.label = "";
            this.chooseTree.value = "";
        },

        // 递归匹配label
        findLabelById(data, id) {
            data &&
                data.forEach((v) => {
                    if (id.includes(v.id.toString())) {
                        this.chooseTree.label += v.label + ",";
                    }
                    v.hasOwnProperty("children")
                        ? this.findLabelById(v.children, id)
                        : "";
                });
        },

        // 关闭弹窗
        close() {
            this.$emit("close");
        },
        // 选择组织架构
        // handleNodeClick(data) {
        //     console.log(data);
        // },
        // 提交表单
        onSubmit() {
            if (this.showCheckbox) {
                if (this.choosePersonList.length === 0) {
                    return this.$message({
                        message: "你未选中数据！",
                        type: "error",
                    });
                }
                if (this.choosePersonList.length > 0) {
                    this.$emit("close", {
                        idArr: this.operatorIds,
                        names: this.personNames,
                        choosePersonInfo: this.choosePersonList,
                    });
                } else {
                    this.$emit("close");
                }
            } else {
                if (!this.operatorId) {
                    return this.$message({
                        message: "你未选中数据！",
                        type: "error",
                    });
                }
                this.$emit(
                    "close",
                    this.operatorId,
                    this.personName,
                    this.choosePerson
                );
            }
        },
        // 根据id数组查询name
        findPersonName() {
            if (this.operatorIds.length > 0) {
                let personNames = [];
                let filterArr = this.personList.filter((item) => {
                    let flag = this.operatorIds.indexOf(item.id) !== -1;
                    if (flag) {
                        personNames.push(item.userName);
                    }
                    return flag;
                });
                this.choosePersonList = filterArr;
                this.personNames = personNames.join("，");
            }
        },
    },

    computed: {},

    watch: {
        // 查询对应的人员列表
        "chooseTree.value": {
            deep: true,
            immediate: true,
            handler() {
                // 公司下的联系人列表
                this.getPersonList(this.chooseTree.value || "");
            },
        },
        // 查询对应的人员列表
        operatorId: {
            deep: true,
            immediate: true,
            handler() {
                if (this.operatorId) {
                    let filterArr = this.personList.filter((item) => {
                        return item.id === this.operatorId;
                    });
                    this.personName = filterArr[0].userName;
                    this.choosePerson = filterArr[0];
                }
            },
        },
        // 查询对应的人员列表
        operatorIds: {
            deep: true,
            immediate: true,
            handler() {
                this.findPersonName();
            },
        },
    },
};
</script>

<style lang='scss' scoped>
.treeBox,
.personList {
    border: 1px solid #ddd;
    height: 300px;
    overflow-y: scroll;
}
.personList .el-radio-group {
    width: 100%;
}
.personList p {
    padding: 8px 5px;
}
.personList p:hover {
    background: #eee;
}
.footer {
    text-align: center;
}
</style>