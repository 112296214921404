// 账户信息
// popAccountDetails
<template>

    <div class="popAccountDetails">
        <el-dialog title="账号信息"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   width="810px"
                   :before-close="close">
            <div class="title">
                <div class="icon"
                     v-if="form.bankIcon"
                     :style="{backgroundImage: `url(${form.bankIcon})`}"></div>
                <div class="icon base"
                     v-else></div>
                <div class="name">{{form.name}}</div>
            </div>
            <div class="infoBox">
                <el-row>
                    <el-col :span="12"
                            v-if="form.type">
                        <span class="label">账户类型</span>
                        <span class="value">{{form.type.text}}</span>
                    </el-col>
                    <el-col :span="12"
                            v-if="form.bankName">
                        <span class="label">开户行</span>
                        <span class="value">{{form.bankName}}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <span class="label">账号</span>
                        <span class="value">{{form.account}}</span>
                    </el-col>
                    <el-col :span="12"
                            v-if="form.accountingSubject">
                        <span class="label">对应会计科目</span>
                        <span class="value">{{form.accountingSubject.text}}</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <span class="label">初始日期</span>
                        <span class="value">{{form.initialDateStr}}</span>
                    </el-col>
                    <el-col :span="12">
                        <span class="label">可用员工</span>
                        <template v-if="form.users.length">
                            <span class="value"
                                  v-for="(item,idx) in form.users"
                                  :key="idx">{{item.name}}{{idx+1!==form.users.length ? '、' : ''}}</span>
                        </template>
                        <span class="value"
                              v-else>全部员工</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <span class="label">备注</span>
                        <span class="value">{{form.remark}}</span>
                    </el-col>
                </el-row>
            </div>
            <div class="countBox">
                <div class="countItem">
                    <span class="title">初始余额</span>
                    <span class="num">{{form.initialAmount}}</span>
                </div>
                <div class="countItem">
                    <span class="title">流入合计</span>
                    <span class="num"
                          style="color: #7E85EE;">{{form.inTotalAmount}}</span>
                </div>
                <div class="countItem">
                    <span class="title">流出合计</span>
                    <span class="num"
                          style="color: #14C2C3;">{{form.outTotalAmount}}</span>
                </div>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import API from "@/api/finance.js";
import { Loading } from "element-ui";
export default {
    name: "popAccountDetails",

    props: {
        // 查询详情用id
        id: {
            type: String,
            default: null,
        },
    },

    components: {},

    data() {
        return {
            form: {
                type: "", // 账户类型
                name: "", // 账户名
                accountHolder: "", // 开户人
                account: "", // 账号
                category: "", // 对应会计科目
                initialAmount: "", // 初始金额
                initialDate: "", // 初始日期
                userIds: "", // 可用员工
                remark: "", // 备注
            },
            dialogVisible: true,
        };
    },

    created() {},

    mounted() {
        // 查询账户详情
        this.getAccountDetails();
    },

    methods: {
        // 查询账户详情
        getAccountDetails() {
            let loading = Loading.service({
                target: document.querySelector(".el-dialog"),
            });
            API.getAccountDetails(this.id)
                .then((res) => {
                    loading.close();
                    this.form = res.content;
                    let userIds = [];
                    this.form.users.forEach((item) => {
                        userIds.push(item.id);
                    });
                    this.form.userIds = userIds;
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {},
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
    padding: 0;
}
.title {
    overflow: hidden;
    padding: 20px;
    .icon {
        width: 64px;
        height: 64px;
        background: #ffffff url("../../../assets/imgs/icon_cash.png") no-repeat
            center center;
        background-size: 50px 50px;
        box-shadow: 0px 2px 3px 0px rgba(114, 123, 129, 0.28);
        border-radius: 50%;
        float: left;
        margin-right: 20px;
    }
    .icon.gonghang {
        background-image: url("../../../assets/imgs/icon_gonghang.png");
    }
    .icon.jianhang {
        background-image: url("../../../assets/imgs/icon_jianhang.png");
    }
    .name {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 64px;
    }
}
.infoBox {
    padding-right: 0;
    border-bottom: 1px solid #f2f5f8;
    span {
        display: inline-block;
        line-height: 50px;
    }
    .label {
        width: 128px;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
    }
    .el-row {
        padding: 0 20px;
    }
    .el-row:nth-of-type(odd) {
        background: #f2f5f8;
    }
}
.countBox {
    // display: flex;
    padding: 48px 0;
    .countItem {
        text-align: left;
        position: relative;
        // flex: 1;
        width: 100%;
        margin-bottom: 20px;
        background: none;
        border: 0;
        .num {
            font-size: 36px;
            font-family: DIN;
            font-weight: bold;
            color: #2c9efb;
            vertical-align: middle;
        }
        .title {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
        }
    }
    .countItem:after {
        content: "";
        display: block;
        width: 2px;
        height: 60px;
        background: #f2f5f8;
        position: absolute;
        right: 0;
        top: 10px;
    }
}
</style>